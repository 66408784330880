import React, { useEffect, memo } from "react"
import styled from "styled-components"
import useMidi from "../../hooks/use-midi"
import useAudio from "../../hooks/use-audio"
import {
  MidiEvent,
  MidiKeys,
  MidiNoteEventPayload,
  distpatchPlayMidiNumbersEvent,
  distpatchStopMidiNumbersEvent,
  playMidiNumbersEventName,
  stopMidiNumbersEventName,
  subscribe,
  unsubscribe,
} from "../../events"
import { getMidiNumber } from "../../libs/music"

const WHITE_KEY_WIDTH = 40
const BLACK_KEY_WIDTH = (WHITE_KEY_WIDTH / 10) * 6
const BLACK_KEY_HEIGHT = 3 * WHITE_KEY_WIDTH
const WHITE_KEY_HEIGHT = 8 * BLACK_KEY_WIDTH

const getWhiteKeyColor = ({ $source }: any) => {
  switch ($source) {
    case "midi":
    case "click":
      return "rgba(0, 0, 255, 1)"
    case "system":
      return "rgba(255, 0, 0, 1)"
    default:
      return "#ffffff"
  }
}
const getBlackKeyColor = ({ $source }: any) => {
  switch ($source) {
    case "midi":
    case "click":
      return "#0a0a94"
    case "system":
      return "#820606"
    default:
      return "#000000"
  }
}

const WhiteKeyStyled = styled.button`
  position: relative;
  background-color: white;
  width: ${WHITE_KEY_WIDTH}px;
  height: ${WHITE_KEY_HEIGHT}px;
  border: 1px solid black;
  display: inline-block;
  overflow: visible;
  background-color: ${getWhiteKeyColor};
`

const BlackKeyStyled = styled.button`
  /* left: 75px; */
  /* right: -15px; */
  /* position: absolute; */
  background-color: black;
  width: ${BLACK_KEY_WIDTH}px;
  height: ${BLACK_KEY_HEIGHT}px;
  display: inline-block;
  background-color: ${getBlackKeyColor};
`
const BlackKeyGap = styled.div`
  /* left: 75px; */
  /* right: -15px; */
  /* position: absolute; */
  visibility: hidden;
  background-color: black;
  width: ${BLACK_KEY_WIDTH}px;
  height: 120px;
  display: block;
`

const OctaveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`
const WhiteKeys = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`
const BlackKeys = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: ${WHITE_KEY_WIDTH - BLACK_KEY_WIDTH / 2}px;
  gap: ${WHITE_KEY_WIDTH - BLACK_KEY_WIDTH}px;
`

const KeyboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`

const useKey = (midNumber: number): MidiKeys => {
  const [key, setKey] = React.useState({})
  const playMidiNumbers = (event: MidiEvent): void => {
    event.detail.forEach((note: MidiNoteEventPayload) => {
      if (midNumber !== note.midiNumber) return
      setKey(note)
    })
  }
  const stopMidiNumbers = (event: MidiEvent): void => {
    event.detail.forEach((note: MidiNoteEventPayload) => {
      if (midNumber !== note.midiNumber) return
      setKey({ note: undefined })
    })
  }
  useEffect(() => {
    subscribe(playMidiNumbersEventName, playMidiNumbers)
    subscribe(stopMidiNumbersEventName, stopMidiNumbers)
    return () => {
      unsubscribe(playMidiNumbersEventName, playMidiNumbers)
      unsubscribe(stopMidiNumbersEventName, stopMidiNumbers)
    }
  }, [setKey])

  return key
}

interface KeyProps {
  onMouseDown: (payload: MidiNoteEventPayload) => void
  onMouseUp: (payload: MidiNoteEventPayload) => void
  onMouseLeave: (payload: MidiNoteEventPayload) => void
  value: MidiNoteEventPayload
  $source?: string
}

const WhiteKey = ({
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  value,
}: KeyProps) => {
  const key = useKey(value.midiNumber)
  return (
    <WhiteKeyStyled
      onMouseDown={() => onMouseDown(value)}
      onMouseUp={() => onMouseUp(value)}
      onMouseLeave={() => onMouseLeave(value)}
      $source={key?.source}
    />
  )
}

const BlackKey = ({
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  value,
}: KeyProps) => {
  const key = useKey(value.midiNumber)
  return (
    <BlackKeyStyled
      onMouseDown={() => onMouseDown(value)}
      onMouseUp={() => onMouseUp(value)}
      onMouseLeave={() => onMouseLeave(value)}
      $source={key?.source}
    />
  )
}

const WHITE_KEYS = ["C", "D", "E", "F", "G", "A", "B"]
const BLACK_KEYS = ["C#", "D#", "GAP", "F#", "G#", "A#"]

const Octave = ({ octaveNumber = 0 }) => {
  const onMouseDown = (payload: MidiNoteEventPayload) => {
    distpatchPlayMidiNumbersEvent([{ ...payload, source: "click" }])
  }
  const onMouseUp = (payload: MidiNoteEventPayload) => {
    distpatchStopMidiNumbersEvent([{ ...payload, source: "click" }])
  }
  const onMouseLeave = (payload: MidiNoteEventPayload) => {
    distpatchStopMidiNumbersEvent([{ ...payload, source: "click" }])
  }
  return (
    <OctaveWrapper>
      <WhiteKeys>
        {WHITE_KEYS.map((note) => (
          <WhiteKey
            key={`${note}-${octaveNumber}`}
            value={{
              octave: octaveNumber,
              note,
              midiNumber: getMidiNumber(note, octaveNumber),
              velocity: 100,
            }}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </WhiteKeys>
      <BlackKeys>
        {BLACK_KEYS.map((note) =>
          note === "GAP" ? (
            <BlackKeyGap key="GAP" />
          ) : (
            <BlackKey
              key={`${note}-${octaveNumber}`}
              value={{
                octave: octaveNumber,
                note,
                midiNumber: getMidiNumber(note, octaveNumber),
                velocity: 100,
              }}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseLeave={onMouseLeave}
            />
          ),
        )}
      </BlackKeys>
    </OctaveWrapper>
  )
}

// interface MidiEvent extends Event {
//   detail: MidiNoteEventPayload[]
// }

// interface MidiKeys {
//   [midiNumber: number]: MidiNoteEventPayload
// }

const Keyboard = () => {
  useMidi()
  return (
    <>
      <KeyboardWrapper>
        <Octave octaveNumber={1} />
        <Octave octaveNumber={2} />
        <Octave octaveNumber={3} />
        <Octave octaveNumber={4} />
      </KeyboardWrapper>
    </>
  )
}

export default memo(Keyboard)
