import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getComposerValue } from "../Features/Composer/selectors"
import { generateSession } from "../libs/music"
import { setSession } from "../Features/Composer/slice"

const useSession = () => {
  const dispatch = useDispatch()
  const key = useSelector(getComposerValue("key"))
  const bpm = useSelector(getComposerValue("bpm"))
  const progression = useSelector(getComposerValue("progression"))
  const patternName = useSelector(getComposerValue("pattern"))
  useEffect(() => {
    if (!patternName) {
      dispatch(setSession({ bars: [] }))
      return
    }
    const updatedSession = generateSession(progression, key, patternName, bpm)
    dispatch(setSession(updatedSession))
  }, [key, progression, patternName, bpm])
}

export default useSession
