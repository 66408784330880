import {
  Bar,
  DiatonicChord,
  MidiNoteEventPayload,
  MidiRef,
  NoteWithOctave,
  ScaleReduceResult,
  ScaleType,
  Session,
} from "../types"
import { v4 } from "uuid"

const majorSteps = [0, 2, 2, 1, 2, 2, 2, 1]
const minorSteps = [0, 2, 1, 2, 2, 1, 2, 2]

const STEPS = {
  MAJOR: majorSteps,
  MINOR: minorSteps,
}

const majorDiatonicChords = [
  "MAJOR",
  "MINOR",
  "MINOR",
  "MAJOR",
  "MAJOR",
  "MINOR",
  "DIMINISHED",
]
const naturalMinorDiatonicChords = [
  "MINOR",
  "DIMINISHED",
  "MAJOR",
  "MINOR",
  "MINOR",
  "MAJOR",
  "MAJOR",
]
const _NOTES = [
  "C/B#",
  "C#/D♭",
  "D",
  "D#/E♭",
  "E/F♭",
  "F/E#",
  "F#/G♭",
  "G",
  "G#/A♭",
  "A",
  "A#/B♭",
  "B/C♭",
]
const NOTE_INDEX = [
  "C",
  "B#",
  "C#",
  "D♭",
  "D",
  "D#",
  "E♭",
  "E",
  "F♭",
  "F",
  "E#",
  "F#",
  "G♭",
  "G",
  "G#",
  "A♭",
  "A",
  "A#",
  "B♭",
  "B",
  "C♭",
]
export const KEYS = [...NOTE_INDEX, ...NOTE_INDEX.map((n) => `${n} minor`)]

const NOTES = [..._NOTES, ..._NOTES, ..._NOTES]
const MIDI_REF: MidiRef = {
  C: 24,
  "B#": 24,
  "C#": 25,
  "D♭": 25,
  D: 26,
  "D#": 27,
  "E♭": 27,
  E: 28,
  "F♭": 28,
  F: 29,
  "E#": 29,
  "F#": 30,
  "G♭": 30,
  G: 31,
  "G#": 32,
  "A♭": 32,
  A: 33,
  "A#": 34,
  "B♭": 34,
  B: 35,
  "C♭": 35,
}
// const NOTE_REF: NoteRef = { "A": "A", "A#": "B♭", "B": "C♭", "C": "B#", "C#": "D♭", "D": "D", "D#": "E♭", "E": "F♭", "F": "E#", "F#": "G♭", "G": "G", "G#": "A♭" }

const getNote = (
  noteIndex: number,
  previousNote: string = "",
  key: string,
): string => {
  const note = NOTES[noteIndex]
  if (note.length === 1) {
    return note
  }
  if (key.length > 1 && note.includes(key)) return key
  const [sharp, flat] = note.split("/")
  if (!previousNote && sharp.includes(key)) return sharp
  if (sharp.includes(previousNote.charAt(0))) {
    return flat
  }
  return sharp
}

const getStartIndex = (key: string): number => {
  return NOTES.findIndex((note) => {
    if (key.length === 1) {
      const actualNote = note.length === 1 ? note : note.split("/")[0]
      return actualNote === key
    }
    return note.includes(key.toUpperCase())
  })
}

const reduceScaleNotes =
  (key: string) =>
  (result: ScaleReduceResult, step: number, currentIndex: number) => {
    const { notes, index } = result
    const note = getNote(index + step, notes[currentIndex - 1], key)
    return { notes: [...notes, note], index: index + step }
  }

export const getScale = (key: string, type: ScaleType): string[] => {
  const startIndex = getStartIndex(key)
  const { notes } = STEPS[type].reduce(reduceScaleNotes(key), {
    notes: [],
    index: startIndex,
  })
  return notes.slice(0, 7)
}

export const getMidiNumber = (note: string, octave: number): number =>
  MIDI_REF[note] + octave * 12

const addOctavesAndMidi = (
  notes: string[],
  startOctave: number,
  key: string,
): NoteWithOctave[] => {
  const firstNote = notes[0]
  const mappedNotes = notes.map((note, i) => {
    let octave = startOctave
    if (
      NOTE_INDEX.indexOf(note) < NOTE_INDEX.indexOf(firstNote) ||
      NOTE_INDEX.indexOf(firstNote) < NOTE_INDEX.indexOf(key)
    )
      octave = startOctave + 1
    // const midiNumber = MIDI_REF[note] + octave * 12
    const midiNumber = getMidiNumber(note, octave)
    return { octave, note, midiNumber }
  })

  return mappedNotes
}

const getShortChordName = (note: string, chordType: string) => {
  switch (chordType) {
    case "major":
      return note
    case "minor":
      return `${note}m`
    case "diminished":
      return `${note}°`
    default:
      return note
  }
}

export const getDiatonicChords = (
  key: string,
  scaleType: ScaleType = "MAJOR",
): DiatonicChord[] => {
  const startOctave = 3
  const scale = getScale(key, scaleType)
  const extendedScale = [...scale, ...scale]
  const chordTypes =
    scaleType === "MAJOR" ? majorDiatonicChords : naturalMinorDiatonicChords
  const mappedScale = scale.map((note, index) => {
    const notes = [
      extendedScale[index],
      extendedScale[index + 2],
      extendedScale[index + 4],
    ]
    const notesWithOctavesAndMidi = addOctavesAndMidi(notes, startOctave, key)
    const chordType = chordTypes[index].toLowerCase()
    return {
      notes: notesWithOctavesAndMidi,
      name: `${note} ${chordType}`,
      shortName: getShortChordName(note, chordType),
    }
  })

  return [
    ...mappedScale,
    {
      ...mappedScale[0],
      notes: mappedScale[0].notes.map((note) => ({
        ...note,
        octave: note.octave + 1,
        midiNumber: MIDI_REF[note.note] + (note.octave + 1) * 12,
      })),
    },
  ]
}

export const getMidiNumberForNoteName = (
  note: string,
): MidiNoteEventPayload => {
  const [baseNote, octave] = note.split("_")
  const midiNumber = getMidiNumber(baseNote, parseInt(octave))
  return { midiNumber, velocity: 100, source: "system" }
}

export const simplePattern = {
  name: "Simple",
  bars: [
    [
      { start: 0, duration: 4, chord: 1 },
      { start: 4, duration: 4, chord: 1 },
      { start: 8, duration: 4, chord: 1 },
      { start: 12, duration: 4, chord: 1 },
    ],
    [
      { start: 0, duration: 4, chord: 2 },
      { start: 4, duration: 4, chord: 2 },
      { start: 8, duration: 4, chord: 2 },
      { start: 12, duration: 4, chord: 2 },
    ],
    [
      { start: 0, duration: 4, chord: 3 },
      { start: 4, duration: 4, chord: 3 },
      { start: 8, duration: 4, chord: 3 },
      { start: 12, duration: 4, chord: 3 },
    ],
    [
      { start: 0, duration: 4, chord: 4 },
      { start: 4, duration: 4, chord: 4 },
      { start: 8, duration: 4, chord: 4 },
      { start: 12, duration: 4, chord: 4 },
    ],
  ],
}

export const quickPattern = {
  name: "Quick",
  bars: [
    [
      { start: 0, duration: 4, chord: 1 },
      { start: 1, duration: 4, chord: 1 },
      { start: 2, duration: 4, chord: 1 },
      { start: 3, duration: 4, chord: 1 },
      { start: 4, duration: 4, chord: 1 },
      { start: 5, duration: 4, chord: 1 },
      { start: 6, duration: 4, chord: 1 },
      { start: 7, duration: 4, chord: 1 },
      { start: 8, duration: 4, chord: 1 },
      { start: 9, duration: 4, chord: 1 },
      { start: 10, duration: 4, chord: 1 },
      { start: 11, duration: 4, chord: 1 },
      { start: 12, duration: 4, chord: 1 },
      { start: 13, duration: 4, chord: 1 },
      { start: 14, duration: 4, chord: 1 },
      { start: 15, duration: 4, chord: 1 },
    ],
    [
      { start: 0, duration: 4, chord: 2 },
      { start: 2, duration: 4, chord: 2 },
      { start: 4, duration: 4, chord: 2 },
      { start: 6, duration: 4, chord: 2 },
      { start: 8, duration: 4, chord: 2 },
      { start: 10, duration: 4, chord: 2 },
      { start: 12, duration: 4, chord: 2 },
      { start: 14, duration: 4, chord: 2 },
    ],
  ],
}
export const patternIdea1 = {
  name: "4 Bars",
  bars: [
    [
      { start: 0, duration: 4, chord: 1 },
      { start: 4, duration: 4, chord: 1 },
      { start: 8, duration: 4, chord: 1 },
      { start: 12, duration: 4, chord: 1 },
    ],
    [
      { start: 0, duration: 4, chord: 2 },
      { start: 2, duration: 4, chord: 2 },
      { start: 8, duration: 4, chord: 2 },
      { start: 10, duration: 4, chord: 2 },
    ],
    [
      { start: 0, duration: 4, chord: 3 },
      { start: 4, duration: 4, chord: 3 },
      { start: 8, duration: 4, chord: 3 },
      { start: 12, duration: 4, chord: 3 },
    ],
    [
      { start: 0, duration: 4, chord: 4 },
      { start: 2, duration: 4, chord: 4 },
      { start: 8, duration: 4, chord: 4 },
      { start: 10, duration: 4, chord: 4 },
    ],
  ],
}
export const patternIdea2 = {
  name: "2 Bars",
  bars: [
    [
      { start: 0, duration: 4, chord: 1 },
      { start: 4, duration: 4, chord: 1 },
      { start: 8, duration: 4, chord: 2 },
      { start: 12, duration: 4, chord: 2 },
    ],
    [
      { start: 0, duration: 4, chord: 3 },
      { start: 4, duration: 4, chord: 3 },
      { start: 8, duration: 4, chord: 4 },
      { start: 12, duration: 4, chord: 4 },
    ],
  ],
}

export const PATTERNS = {
  fourChords: [simplePattern, patternIdea1, patternIdea2, quickPattern],
}

export const PROGRESSIONS = [
  {
    scaleType: "MAJOR",
    chords: [
      { number: 1, name: "I" },
      { number: 5, name: "V" },
      { number: 6, name: "VI" },
      { number: 4, name: "IV" },
    ],
    name: "I-V-VI-IV",
    patterns: PATTERNS.fourChords,
  },
]

const calculateStartTime = (
  start: number,
  barIndex: number,
  bpm: number,
): number => {
  const beatsPerBar = 4
  const barTimeMs = (60000 / bpm) * beatsPerBar
  const sixteenthNoteTimeMs = barTimeMs / 16
  const timeInBar = start * sixteenthNoteTimeMs
  const positionTime = barIndex * barTimeMs + timeInBar
  return positionTime
}

/**
 * Retrieves a musical progression based on the provided parameters and constructs a sequence of bars with chords.
 *
 * This function searches for a predefined progression by its name and a pattern within that progression, also by name.
 * It then maps the pattern's bars to the corresponding diatonic chords based on the key and key type (major or minor).
 * Each chord in a bar is enriched with details such as start time, duration, chord name, and the MIDI numbers of the notes,
 * assuming a default velocity of 100 for all notes, and marking the source as "system".
 *
 * @param {string} progression - The name of the progression to retrieve.
 * @param {string} key - The musical key of the progression (e.g., "C", "A minor").
 * @param {string} patternName - The name of the pattern within the progression to use.
 * @returns {Session} An object representing the progression, including an array of bars, each containing details about the chords.
 *
 * @example
 * // Assuming a progression named "Basic" in the key of "C major" with a "Simple" pattern exists:
 * generateSession("Basic", "C", "Simple");
 * // Returns a progression object with bars and chords mapped to the "C major" diatonic chords.
 */
export const generateSession = (
  progressionName: string,
  key: string,
  patternName: string,
  bpm: number,
): Session => {
  const keyType = key.includes("minor") ? "MINOR" : "MAJOR"
  const diatonicChords = getDiatonicChords(key, keyType)
  const progression = PROGRESSIONS.find((p) => p.name === progressionName)
  const pattern = progression?.patterns.find((p) => p.name === patternName)
  const chordMap: { [key: string]: DiatonicChord } = {}
  const chords: Bar[] = pattern?.bars.map((barChords, barIndex) => {
    return barChords.map((chord) => {
      const diatonicChord =
        diatonicChords[progression?.chords[chord.chord - 1].number - 1]
      const chordId = v4()
      chordMap[chordId] = diatonicChord
      return {
        startTime: calculateStartTime(chord.start, barIndex, bpm),
        start: chord.start,
        duration: chord.duration,
        chordName: diatonicChord.shortName,
        id: chordId,
        notes: diatonicChord.notes.map((note: NoteWithOctave) => {
          return {
            midiNumber: note.midiNumber,
            velocity: 100,
            source: "system",
          }
        }),
      }
    })
  })
  return { bars: chords, chordMap }
}
