import React, { useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import Learn from "./Features/Learn/index"

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" Component={Learn} />
    </Routes>
  )
}

export default MainRoutes
