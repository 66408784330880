import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import React, { ChangeEvent, ChangeEventHandler } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getComposerValue } from "../Composer/selectors"
import {
  setBPM,
  setKey,
  setPattern,
  setProgressionName,
  setStart,
  toggleDemo,
  toggleMetronome,
} from "../Composer/slice"
import { KEYS, PROGRESSIONS } from "../../libs/music"

const Toolbar = () => {
  const dispatch = useDispatch()
  const demo = useSelector(getComposerValue("demo"))
  const bpm = useSelector(getComposerValue("bpm"))
  const key = useSelector(getComposerValue("key"))
  const pattern = useSelector(getComposerValue("pattern"))
  const progression = useSelector(getComposerValue("progression"))
  const metronome = useSelector(getComposerValue("metronome"))
  const start = useSelector(getComposerValue("start"))
  const patterns =
    PROGRESSIONS.find((p) => p.name === progression)?.patterns || []
  const onBPMChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setBPM(parseInt(e.target.value)))
  }
  const handleKeyChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setKey(e.target.value))
  }
  const handleProgressionChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setProgressionName(e.target.value))
  }
  const handlePatternChange: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setPattern(e.target.value))
  }
  const handleToggleMetronome: ChangeEventHandler<HTMLInputElement> = () => {
    dispatch(toggleMetronome())
  }
  const handleToggleDemo: ChangeEventHandler<HTMLInputElement> = () => {
    dispatch(toggleDemo())
  }
  const handleSetStart = (): void => {
    dispatch(setStart())
  }
  return (
    <Stack p={2}>
      <Stack direction={"row"} spacing={2}>
        <Button onClick={handleSetStart} variant="contained" type="button">
          {start ? "stop" : "start"}
        </Button>
        <TextField
          disabled={start}
          sx={{ width: 100 }}
          value={bpm}
          id="outlined-number"
          label="Tempo (BPM)"
          type="number"
          onChange={onBPMChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <FormControl>
          <InputLabel id="toolbar-key-select-label">Key</InputLabel>
          <Select
            disabled={start}
            labelId="toolbar-key-select-label"
            id="toolbar-key-select"
            value={key}
            label="Key"
            onChange={handleKeyChange}
          >
            {KEYS.map((note) => (
              <MenuItem key={note} value={note}>
                {note}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="toolbar-progression-select-label">
            Progression
          </InputLabel>
          <Select
            disabled={start}
            sx={{ width: 110 }}
            labelId="toolbar-progression-select-label"
            id="toolbar-progression-select"
            value={progression}
            label="Progression"
            onChange={handleProgressionChange}
          >
            {PROGRESSIONS.map((progression) => (
              <MenuItem key={progression.name} value={progression.name}>
                {progression.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="toolbar-progression-select-label">Pattern</InputLabel>
          <Select
            disabled={start}
            sx={{ width: 150 }}
            labelId="toolbar-progression-select-label"
            id="toolbar-progression-select"
            value={pattern}
            label="Pattern"
            onChange={handlePatternChange}
          >
            {patterns.map((pattern) => (
              <MenuItem key={pattern.name} value={pattern.name}>
                {pattern.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={metronome} />}
          onChange={handleToggleMetronome}
          label="Metronome"
        />
        <FormControlLabel
          control={<Checkbox checked={demo} />}
          onChange={handleToggleDemo}
          label="Demo"
        />
      </Stack>
    </Stack>
  )
}

export default Toolbar
