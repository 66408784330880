import { configureStore } from "@reduxjs/toolkit"
import composerReducer from "../Features/Composer/slice"

const store = configureStore({
  reducer: {
    composer: composerReducer,
  },
})

export default store
