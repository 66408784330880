export const AUDIO_FILES: string[] = [
  "https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A0.mp3",
  "https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb0.mp3",
  "https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B0.mp3",
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B1.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B2.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B3.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B4.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B5.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B6.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/D7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Eb7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/E7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/F7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Gb7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/G7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Ab7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/A7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Bb7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/B7.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/C8.mp3',
  'https://s3.eu-west-2.amazonaws.com/chord-trainer.blackcatsdigital.com/Db8.mp3',
]