import React, { useEffect } from "react"
import styled from "styled-components"
import { timeUpdateEventName, subscribe, unsubscribe } from "../../events"
import useSession from "../../hooks/use-session"
import {
  Bar,
  BarProps,
  ChordProps,
  ChordsProps,
  PlayHeadAttrProps,
  PlayHeadProps,
} from "../../types"
import { getComposerValue } from "../Composer/selectors"
import { useSelector } from "react-redux"

const PatternViewStyled = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
`

const BarStyled = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  border-right: 2px solid #454444;
  border-left: 2px solid #454444;
`

const Bars = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

const Beat = styled.div`
  width: 25%;
  border-right: 1px solid #434343;
  display: flex;
  &:last-child {
    border-right: none;
  }
`

const QuarterBeat = styled.div`
  width: 25%;
  border-right: 1px solid #cecece;
  &:last-child {
    border-right: none;
  }
`

const playHeadAttrs: PlayHeadAttrProps = (props: PlayHeadProps) => ({
  style: {
    left: `${props.$left}%`,
  },
})

const PlayHeadStyled = styled.div.attrs<PlayHeadAttrProps>(
  playHeadAttrs,
)<PlayHeadProps>`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #ff0000;
  z-index: 100;
`

const calculateChordPos = ({ $chord }: ChordProps) => {
  return ($chord.start / 16) * 100
}

const ChordWrapper = styled.div<ChordProps>`
  position: absolute;
  left: ${calculateChordPos}%;
  top: calc(50% - 30px);
  font-size: 30px;
`

const BarChords = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Chord = ({ $chord }: ChordProps) => {
  return <ChordWrapper $chord={$chord}>{$chord.chordName}</ChordWrapper>
}

const Chords = ({ chords }: ChordsProps) => {
  return (
    <BarChords>
      {chords.map((chord, index) => {
        return <Chord key={index} $chord={chord} />
      })}
    </BarChords>
  )
}

const BarComponent = ({ bar }: BarProps) => {
  return (
    <BarStyled>
      <Chords chords={bar} />
      <Beat>
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
      </Beat>
      <Beat>
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
      </Beat>
      <Beat>
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
      </Beat>
      <Beat>
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
        <QuarterBeat />
      </Beat>
    </BarStyled>
  )
}

const PlayHead = () => {
  const [playHeadPosition, setPlayHeadPosition] = React.useState(0)
  useEffect(() => {
    const timelinePosition = (event: CustomEvent) => {
      setPlayHeadPosition(event.detail.percent)
    }
    subscribe(timeUpdateEventName, timelinePosition)
    return () => {
      unsubscribe(timeUpdateEventName, timelinePosition)
    }
  }, [])
  const playHeadProps = { $left: playHeadPosition }
  return <PlayHeadStyled {...playHeadProps} />
}

const Timeline = ({}) => {
  useSession()
  const session = useSelector(getComposerValue("session"))
  return (
    <PatternViewStyled>
      <PlayHead />
      <Bars>
        {session.bars.map((bar: Bar, index: number) => {
          return <BarComponent key={index} bar={bar}></BarComponent>
        })}
      </Bars>
    </PatternViewStyled>
  )
}

export default Timeline
