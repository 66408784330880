import React, { useEffect } from "react"
// import PianoKeyboard from "../PianoKeyboard/index"
import { getDiatonicChords } from "../../libs/music"
import Keyboard from "../Keyboard"
import { distpatchPlayMidiNumbersEvent } from "../../events"
import useComposer from "../../hooks/use-composer"
import Timeline from "../Timeline"
import useAudio from "../../hooks/use-audio"
import Toolbar from "../Toolbar"
import { NoteWithOctave } from "../../types"
import useTracker from "../../hooks/use-tracker"
import Tracker from "../Tracker"
import { Button, Stack } from "@mui/material"

const Learn = () => {
  const [begin, setBegin] = React.useState(false)
  const [start, setStart] = React.useState(false)
  const [chords, setChords] = React.useState([])
  const { ready } = useAudio(begin)

  useComposer(ready)
  useEffect(() => {
    const diatonicChords = getDiatonicChords("C", "MAJOR")
    setChords(diatonicChords)
  }, [])

  const playChord = (index: number) => {
    const chord = chords[index]
    const midiNumbers = chord.notes.map((note: NoteWithOctave) => ({
      midiNumber: note.midiNumber,
      velocity: 100,
      source: "system",
    }))
    distpatchPlayMidiNumbersEvent(midiNumbers)
  }

  if (!begin) {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <Button
          disabled={begin}
          onClick={() => setBegin(true)}
          variant={"contained"}
          size="large"
        >
          BEGIN
        </Button>
      </Stack>
    )
  }
  return (
    <div>
      <Toolbar />
      {start &&
        chords.map((chord, index) => (
          <button key={index} onClick={() => playChord(index)}>
            {chord.name}
          </button>
        ))}
      <Timeline />
      {begin && <Keyboard init={begin} />}
      <Tracker />
    </div>
  )
}

export default Learn
