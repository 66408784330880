import React from "react"
import { BrowserRouter } from "react-router-dom"
import Routes from "./Routes"
import { Provider } from "react-redux"
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import store from "./state/store"

const ProvidedApp = () => {
  return <Routes />
}

function App(): JSX.Element {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
        },
      }),
    [],
  )
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ProvidedApp />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App
