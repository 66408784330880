import { useDispatch, useSelector } from "react-redux"
import { getComposerValue, getKeys } from "../Features/Composer/selectors"
import { useCallback, useEffect, useState } from "react"
import {
  startTimeEventName,
  subscribe,
  timeUpdateEventName,
  unsubscribe,
} from "../events"
import { setChordReadLock } from "../Features/Composer/slice"

const getNotesAccuracy = (midiNumbers, currentChord) => {
  return currentChord.notes.reduce((acc, note) => {
    const correct = midiNumbers.includes(note.midiNumber)
    return correct ? acc + 1 : acc
  }, 0)
}

const getPrecision = (playPoint, midiNumbers, currentChord, bpm) => {
  const accuracyMargin = 60000 / bpm / 4 // 16th note
  const targetTime = currentChord?.startTime
  const delta = playPoint - targetTime
  const absDelta = Math.abs(delta)
  if (absDelta > accuracyMargin) {
    return 0
  }

  const percent = 100 - (absDelta / accuracyMargin) * 100
  return { percent, delta, accuracyMargin }

  // const precision = 1 - delta / accuracyMargin
}

const calculateScore = (playPoint, currentChord, midiNumbers, bpm) => {
  const notesAccuracy = getNotesAccuracy(midiNumbers, currentChord)
  const precision = getPrecision(playPoint, midiNumbers, currentChord, bpm)
  return {
    precision,
    notesAccuracy,
  }
}
const useTracker = () => {
  const dispatch = useDispatch()
  const [currentChord, setCurrentChord] = useState(null)
  const [startTime, setStartTime] = useState(0)
  const keys = useSelector(getKeys)
  const chordReadLock = useSelector(getComposerValue("chordReadLock"))
  const bpm = useSelector(getComposerValue("bpm"))
  const handleTimeUpdate = useCallback((event) => {
    if (event?.detail?.chord && event?.detail?.chord?.id !== currentChord?.id) {
      setCurrentChord(event.detail.chord)
    }
  }, [])
  const handleStartTimeUpdate = useCallback((event) => {
    setStartTime(event.detail.startTime)
  }, [])

  useEffect(() => {
    const keyMidiStrings = Object.keys(keys)
    if (keyMidiStrings.length === 3 && !chordReadLock) {
      const midiNumbers = keyMidiStrings.map((n) => parseInt(n, 10))
      dispatch(setChordReadLock(true))
      const playTime = Date.now()
      const playPoint = playTime - startTime
      const score = calculateScore(playPoint, currentChord, midiNumbers, bpm)
      console.log({ score })
      // console.log({
      //   delta: score.delta,
      //   chord: currentChord.chordName,
      //   midiNumbers,
      // })
      // if(playTime > startTime) {
      // const playPoint = startTime
      // console.log("CHECK IF KEYS MATCH THE CHORDS")
      // console.log({ currentChord, midiNumbers, startTime, playPoint })
      // if
    }

    if (chordReadLock && keyMidiStrings.length === 0) {
      dispatch(setChordReadLock(false))
    }
  }, [keys, chordReadLock, currentChord, startTime])

  useEffect(() => {
    subscribe(timeUpdateEventName, handleTimeUpdate)
    subscribe(startTimeEventName, handleStartTimeUpdate)
    return () => {
      unsubscribe(timeUpdateEventName, handleTimeUpdate)
    }
  }, [])
}

export default useTracker
