import React, { useEffect } from "react"
import {
  dispatchStartTimeEvent,
  dispatchTimeEvent,
  distpatchPlayMidiNumbersEvent,
  distpatchStopMidiNumbersEvent,
} from "../events"
import { useSelector } from "react-redux"
import { getComposerValue } from "../Features/Composer/selectors"
import useSession from "./use-session"
import { Session } from "../types"

const tick1 = new Audio("tick1.mp3")
const tick2 = new Audio("tick2.mp3")

const chordChangeMargin = 0.5

const getTime = (startTime: number) => {
  return Date.now() - startTime
}
export class Composer {
  session: Session
  startTime: number
  currentBarTime: number
  barLengthTime: number
  sixteenthNoteTime: number
  barIndex: number
  barChordIndex: number
  interval: NodeJS.Timeout
  stopNotes: NodeJS.Timeout[]
  percent: number
  totalTime: number
  bpm: number
  metronome: boolean
  nextTickTime: number
  currentTime: number
  lastPlayedChord: string
  allChords: any[]
  allChordsIndex: number
  demo: boolean

  constructor(session: any, bpm: number, metronome: boolean = true) {
    this.allChordsIndex = 0
    this.session = session
    this.play = this.play.bind(this)
    this.stop = this.stop.bind(this)
    this.stopNotes = []
    this.bpm = bpm
    this.metronome = metronome
    this.nextTickTime = 0
    this.currentTime = 0
    this.updateTimes = this.updateTimes.bind(this)
    this.allChords = session.bars.flat()
  }

  setBpm(bpm: number) {
    this.bpm = bpm
    this.barLengthTime = (60000 / this.bpm) * 4
    this.sixteenthNoteTime = this.barLengthTime / 16
  }

  setSession(session: Session) {
    this.session = session
    this.allChords = session.bars.flat()
  }

  getNextChord() {
    const chord = this.allChords[this.allChordsIndex]
    return chord
  }

  setMetronome(metronome: boolean) {
    this.metronome = metronome
  }

  setDemo(demo: boolean) {
    this.demo = demo
  }

  stopNote(note: any, duration: number) {
    if (this.stopNotes[note.midiNumber])
      clearTimeout(this.stopNotes[note.midiNumber])
    this.stopNotes[note.midiNumber] = setTimeout(() => {
      distpatchStopMidiNumbersEvent([note])
    }, duration)
  }

  stop() {
    clearInterval(this.interval)
  }

  updateTimes() {
    this.currentBarTime = getTime(this.startTime)
    this.currentTime = this.barIndex * this.barLengthTime + this.currentBarTime
    this.percent = (this.currentTime / this.totalTime) * 100
    // if the currentTime is greater than the start time of the next bar set the start time to now
    if (this.currentBarTime >= this.barLengthTime) {
      this.startTime = Date.now()
      this.barIndex += 1
      this.barChordIndex = 0
    }
    // if we are at the end of the session start over
    if (this.barIndex === this.session.bars.length) {
      this.barIndex = 0
      this.nextTickTime = 0
      dispatchStartTimeEvent(Date.now())
    }
  }

  metronomeTick() {
    const ticksPerBar = 4
    const tickGap = this.totalTime / this.session.bars.length / ticksPerBar
    this.nextTickTime += tickGap
    tick1.play()
  }

  countIn() {
    return new Promise<void>((resolve) => {
      let playCount = 0
      const barTime = (60000 / this.bpm) * 4
      const startTime = Date.now()
      let currentTime = startTime
      let nextTickTime = startTime
      let countInInterval = setInterval(() => {
        currentTime = Date.now()
        if (currentTime >= nextTickTime && playCount < 4) {
          tick1.play()
          nextTickTime += barTime / 4
          playCount += 1
        }
        if (currentTime >= startTime + barTime) {
          clearInterval(countInInterval)
          resolve()
        }
      }, 30)
    })
  }

  async play() {
    this.barIndex = 0
    this.barChordIndex = 0
    this.barLengthTime = (60000 / this.bpm) * 4
    this.sixteenthNoteTime = this.barLengthTime / 16
    this.totalTime = this.barLengthTime * this.session.bars.length
    const processInterval = () => {
      // update the current time and percent
      this.updateTimes()

      // get the current beat
      const beat = this.currentBarTime / this.sixteenthNoteTime
      // const currentBar = this.session.bars[this.barIndex]
      const currentChord = this.getNextChord()
      // dispatch the percent event
      dispatchTimeEvent({
        percent: this.percent,
        currentTime: this.currentTime,
        barIndex: this.barIndex,
        chord: currentChord,
      })

      // if there is no current bar return
      // play the metronome
      if (this.metronome && this.currentTime >= this.nextTickTime) {
        this.metronomeTick()
      }
      // const currentChord = currentBar?.[this.barChordIndex]
      // if (!currentChord) return
      const firstChordButGreaterThanOneBarTime =
        this.allChordsIndex === 0 &&
        this.currentTime > this.sixteenthNoteTime * 16
      if (
        currentChord &&
        this.currentTime >= currentChord.startTime &&
        this.lastPlayedChord !== currentChord.id &&
        !firstChordButGreaterThanOneBarTime
      ) {
        this.lastPlayedChord = currentChord.id
        // only play each chord once by also storing the last played chord as a variable on the context
        // this.lastPlayedChord
        // check in the above if to see if the current chord is the same, if it is do not play it
        // set the last play chord in here somewhere

        // if the beat is greater than the start time of the current chord play the chord
        // get the duration and notes of the current chord
        const duration = currentChord.duration * this.sixteenthNoteTime
        // play the notes of the current chord
        const notes = currentChord.notes
        if (this.demo) {
          distpatchPlayMidiNumbersEvent(notes)
        }
        // stop the notes after the duration
        notes.forEach((note) => this.stopNote(note, duration))
        // increment the chord index
      }
      if (
        this.currentTime >=
          currentChord.startTime + this.sixteenthNoteTime / 1 &&
        !firstChordButGreaterThanOneBarTime
      ) {
        this.allChordsIndex += 1
        if (this.allChordsIndex >= this.allChords.length) {
          this.allChordsIndex = 0
        }
      }
    }
    await this.countIn()
    dispatchStartTimeEvent(this.startTime)
    this.startTime = Date.now()
    processInterval()
    this.interval = setInterval(processInterval, 20)
  }
}

const useComposer = (ready: boolean) => {
  const [composer, setComposer] = React.useState<Composer | null>(null)
  const bpm = useSelector(getComposerValue("bpm"))
  const session = useSelector(getComposerValue("session"))
  const metronome = useSelector(getComposerValue("metronome"))
  const demo = useSelector(getComposerValue("demo"))
  useSession()
  const patternName = useSelector(getComposerValue("pattern"))
  const start = useSelector(getComposerValue("start"))
  useEffect(() => {
    if (start && ready && session && !composer) {
      const newComposer = new Composer(session, bpm, metronome)
      newComposer.play()
      setComposer(newComposer)
    }
    if (!start && composer) {
      dispatchTimeEvent({
        percent: 0,
        currentTime: 0,
        barIndex: 0,
        chord: null,
      })
      composer.stop()
      setComposer(null)
    }
  }, [session, start, ready, metronome])
  useEffect(() => {
    if (composer) {
      composer.setBpm(bpm)
      composer.setSession(session)
      composer.setMetronome(metronome)
      composer.setDemo(demo)
    }
  }, [bpm, composer, patternName, session, metronome, demo])
}

export default useComposer
