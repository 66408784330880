import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Bar, MidiEvent, Session } from "../../types"
// import {
//   fetchDataFromAPI,
// } from "./chatsAPI"

// const initialState = {
//   data: [],
//   status: "idle",
//   error: null,
// }

// export const fetchData = createAsyncThunk(
//   "home/fetchData",
//   fetchDataFromAPI
// )

interface StringPayload {
  type: string
  payload: string
}
interface SessionPayload {
  type: string
  payload: Session
}

interface NumberPayload {
  type: string
  payload: number
}

interface ComposerState {
  progression?: string
  key?: string
  bpm?: number
  pattern?: string
  metronome: boolean
  start: boolean
  ready: boolean
  keys: { [key: number]: any }
  chordReadLock: boolean
  session?: any
  demo: boolean
}

const initialState: ComposerState = {
  progression: "I-V-VI-IV",
  key: "C",
  bpm: 140,
  pattern: "Simple",
  metronome: true,
  start: false,
  ready: false,
  keys: {},
  chordReadLock: false,
  session: null,
  demo: false,
}

const composerSlice = createSlice({
  name: "composer",
  initialState,
  reducers: {
    setKeyDown: (state: ComposerState, action: NumberPayload) => {
      state.keys = { ...state.keys, [action.payload]: true }
    },
    setKeyUp: (state: ComposerState, action: NumberPayload) => {
      const keys = { ...state.keys }
      delete keys[action.payload]
      state.keys = keys
    },
    setKey: (state: ComposerState, action: StringPayload) => {
      state.key = action.payload
    },
    setBPM: (state: ComposerState, action: NumberPayload) => {
      state.bpm = action.payload
    },
    setProgressionName: (state: ComposerState, action: StringPayload) => {
      state.progression = action.payload
    },
    setSession: (state: ComposerState, action: SessionPayload) => {
      state.session = action.payload
    },
    setPattern: (state: ComposerState, action: StringPayload) => {
      state.pattern = action.payload
    },
    toggleMetronome: (state: ComposerState) => {
      state.metronome = !state.metronome
    },
    toggleDemo: (state: ComposerState) => {
      state.demo = !state.demo
    },
    setStart: (state: ComposerState) => {
      state.start = !state.start
    },
    setReady: (state: ComposerState) => {
      state.ready = !state.ready
    },
    setChordReadLock: (state: ComposerState, action) => {
      state.chordReadLock = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchData.pending, (state) => {
  //       state.status = "loading"
  //     })
  //     .addCase(fetchData.fulfilled, (state, action) => {
  //       state.status = "succeeded"
  //       state.data = action.payload
  //     })
  //     .addCase(fetchData.rejected, (state, action) => {
  //       state.status = "failed"
  //       state.error = action.error.message
  //     })
  // },
})

export const {
  setKey,
  setBPM,
  setProgressionName,
  setSession,
  setPattern,
  toggleMetronome,
  setStart,
  setReady,
  setKeyDown,
  setKeyUp,
  setChordReadLock,
  toggleDemo,
} = composerSlice.actions
export default composerSlice.reducer
