import { useEffect } from "react"
import {
  distpatchPlayMidiNumbersEvent,
  distpatchStopMidiNumbersEvent,
} from "../events"
import { useDispatch } from "react-redux"
import { setKeyDown, setKeyUp } from "../Features/Composer/slice"

const getMIDIMessage =
  (setKeyInState: setKeyInState) => (midiMessage: MIDIMessageEvent) => {
    // [144, 72, 64] [action, note, velocity] actions: key down: 144, key up: 128
    const [action, note, velocity] = midiMessage.data
    const midiNumber = note
    if (action === 144) {
      // KEY
      distpatchPlayMidiNumbersEvent([
        {
          midiNumber,
          velocity,
          source: "midi",
          time: Date.now(),
        },
      ])
      setKeyInState(midiNumber, false)
    }
    if (action === 128) {
      // KEY UP
      distpatchStopMidiNumbersEvent([
        {
          midiNumber,
          velocity,
          source: "midi",
          time: Date.now(),
        },
      ])
      setKeyInState(midiNumber, true)
    }
  }

type setKeyInState = (key: number, up: boolean) => void

const onMIDISuccess =
  (setKeyInState: setKeyInState) => (midiAccess: MIDIAccess) => {
    for (var input of midiAccess.inputs.values()) {
      input.onmidimessage = getMIDIMessage(setKeyInState)
    }
  }

function onMIDIFailure() {
  console.warn("Could not access your MIDI devices.")
}

const useMidi = () => {
  const dispatch = useDispatch()
  const setKeyInState = (key: any, up: boolean = false) => {
    if (up) {
      dispatch(setKeyUp(key))
    } else {
      dispatch(setKeyDown(key))
    }
  }
  useEffect(() => {
    if (navigator.requestMIDIAccess) {
      console.info("This browser supports WebMIDI!")
      navigator
        .requestMIDIAccess()
        .then(onMIDISuccess(setKeyInState), onMIDIFailure)
    } else {
      console.warn("WebMIDI is not supported in this browser.")
    }
  }, [])
}

export default useMidi
