import { useCallback, useEffect, useState } from "react"
import { AUDIO_FILES } from "../constants/audio-files"
import {
  audioLoadedEventName,
  dispatchAudioLoadedEvent,
  playMidiNumbersEventName,
  stopMidiNumbersEventName,
  subscribe,
  unsubscribe,
} from "../events"
import { MidiEvent, MidiNoteEventPayload, PlayingSounds } from "../types"

class Sound {
  context: AudioContext
  buffer: AudioBuffer
  gainNode: GainNode
  source: AudioBufferSourceNode
  velocity: number

  constructor(context: AudioContext, buffer: AudioBuffer) {
    this.context = context
    this.buffer = buffer
    this.velocity = 1
  }

  init() {
    this.gainNode = this.context.createGain()
    this.source = this.context.createBufferSource()
    this.source.buffer = this.buffer
    this.source.connect(this.gainNode)
    this.gainNode.connect(this.context.destination)
  }

  play(velocity: number) {
    this.init()
    // velocity is a range between 0 and 0 to 127
    this.gainNode.gain.value = this.gainNode.gain.value * (velocity / 127)
    this.source.start(this.context.currentTime)
  }

  stop() {
    this.gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      this.context.currentTime + 0.5,
    )
    this.source.stop(this.context.currentTime + 0.5)
  }
}

class Sounds {
  context: AudioContext
  urls: string[]
  buffer: AudioBuffer[]
  playingSounds: PlayingSounds

  constructor(context: AudioContext, urls: string[]) {
    this.context = context
    this.urls = urls
    this.buffer = []
    this.playingSounds = {}
    this.playNote = this.playNote.bind(this)
    this.stopNote = this.stopNote.bind(this)
    this.playNotes = this.playNotes.bind(this)
    this.stopNotes = this.stopNotes.bind(this)
    this.loadAllPromise = this.loadAllPromise.bind(this)
  }

  async loadSoundAsync(url: string, index: number) {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer()
    const buffer = await this.context.decodeAudioData(arrayBuffer)
    return buffer
  }

  async loadAllPromise() {
    this.buffer = await Promise.all(
      this.urls.map((url, index) => this.loadSoundAsync(url, index)),
    )
    this.loaded()
  }

  loaded() {
    // what happens when all the files are loaded
    console.info("ALL FILES LOADED")
    dispatchAudioLoadedEvent()
  }

  getSoundByIndex(index: number) {
    return this.buffer[index]
  }

  playNote(midiId: number, velocity: number) {
    const soundIndex = midiId - offset
    const sound = new Sound(this.context, this.getSoundByIndex(soundIndex))
    sound.play(velocity)
    this.playingSounds[soundIndex] = sound
  }

  playNotes(notes: MidiNoteEventPayload[]) {
    notes.forEach((note: MidiNoteEventPayload) => {
      this.playNote(note.midiNumber, note.velocity)
    })
  }

  stopNote = (midiId: number) => {
    const soundIndex = midiId - offset
    if (!this.playingSounds[soundIndex]) return
    this.playingSounds[soundIndex].stop()
    this.playingSounds[soundIndex] = undefined
  }

  stopNotes = (midiNumbers: MidiNoteEventPayload[]) => {
    midiNumbers.forEach((note: MidiNoteEventPayload) => {
      this.stopNote(note.midiNumber)
    })
  }
}

const offset = 21

const useAudio = (initialise = false) => {
  const [ready, setReady] = useState(false)
  const audioReady = useCallback(() => {
    setReady(true)
  }, [])
  useEffect(() => {
    let sounds: Sounds
    subscribe(audioLoadedEventName, audioReady)
    const playNotes = (event: MidiEvent) => {
      sounds.playNotes(event.detail)
    }
    const stopNotes = (event: MidiEvent) => {
      sounds.stopNotes(event.detail)
    }
    const init = async () => {
      const audioContext: AudioContext = new window.AudioContext()
      sounds = new Sounds(audioContext, AUDIO_FILES)
      await sounds.loadAllPromise()
      subscribe(playMidiNumbersEventName, playNotes)
      subscribe(stopMidiNumbersEventName, stopNotes)
      unsubscribe(audioLoadedEventName, audioReady)
    }
    if (initialise) {
      init()
    }
    return () => {
      unsubscribe(playMidiNumbersEventName, playNotes)
      unsubscribe(stopMidiNumbersEventName, stopNotes)
    }
  }, [initialise])

  return { ready }
}

export default useAudio
