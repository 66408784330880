import { getMidiNumber } from "../libs/music"
import { ChordProps, ChordType, MidiNoteEventPayload } from "../types"

export const midiNoteEventName = "MIDI_NOTE"
export const midiNumberEventName = "MIDI_NUMBER"
export const midiNotesEventName = "MIDI_NOTES"
export const playMidiNumbersEventName = "PLAY_MIDI_NUMBERS"
export const stopMidiNumbersEventName = "STOP_MIDI_NUMBERS"
export const audioLoadedEventName = "AUDIO_LOADED"
export const timeUpdateEventName = "TIME_UPDATE"
export const startTimeEventName = "START_TIME"

export const element = document

export const distpatchPlayMidiNumbersEvent = (
  payload: MidiNoteEventPayload[],
): void => {
  const playMidiNotesEvent = new CustomEvent(playMidiNumbersEventName, {
    detail: payload,
  })
  element.dispatchEvent(playMidiNotesEvent)
}

export const distpatchStopMidiNumbersEvent = (
  payload: MidiNoteEventPayload[],
): void => {
  const stopMidiNotesEvent = new CustomEvent(stopMidiNumbersEventName, {
    detail: payload,
  })
  element.dispatchEvent(stopMidiNotesEvent)
}

export const distpatchMidiNotesEvent = (
  payload: MidiNoteEventPayload[],
): void => {
  const midiNumbers = payload.map((note) => ({
    midiNumber: getMidiNumber(note.note, note.octave),
    velocity: note.velocity || 100,
    source: note.source,
    state: note.state,
  }))
  distpatchPlayMidiNumbersEvent(midiNumbers)
}

export const dispatchAudioLoadedEvent = (): void => {
  const audioLoadedEvent = new CustomEvent(audioLoadedEventName, {
    detail: true,
  })
  element.dispatchEvent(audioLoadedEvent)
}

export const dispatchTimeEvent = (payload: {
  percent: number
  currentTime: number
  barIndex: number
  chord: ChordType
}): void => {
  const percentEvent = new CustomEvent(timeUpdateEventName, {
    detail: payload,
  })
  element.dispatchEvent(percentEvent)
}

export const subscribe = (
  eventName: string,
  callback: (event: CustomEvent) => void,
): void => {
  element.addEventListener(eventName, callback)
}

export const unsubscribe = (
  eventName: string,
  callback: (event: CustomEvent) => void,
): void => {
  element.removeEventListener(eventName, callback)
}

export const dispatchStartTimeEvent = (startTime: number): void => {
  const startTimeEvent = new CustomEvent(startTimeEventName, {
    detail: { startTime },
  })
  element.dispatchEvent(startTimeEvent)
}
